/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  // {
  //   url: "/",
  //   name: "Home",
  //   slug: "external",
  //   i18n: "Home",
  //   image:`${require("@/assets/images/menu/home.png")}`,
  //   show:true,
  //   outlink:true,
  //   href:'https://doclinia.com/',

  // },
  {
    show: true,
    url: "/patientServices",
    name: "PatientServices",
    slug: "patientServices",
    i18n: "PatientServices",
    image:`${require("@/assets/images/menu/skillshare.png")}`,
  },
  {
    show: true,
    url: "/travelArrangments",
    name: "TravelArrangements",
    slug: "travelArrangments",
    i18n: "TravelArrangements",
    image:`${require('@/assets/images/menu/TravelArrangementsIcon.png')}`,
  },
   {
    show: true,
    url: "/OurPackages",
    name: "OurPackages",
    slug: "OurPackages",
    i18n: "OurPackages",
    image:`${require('@/assets/images/menu/PackagesIcon.png')}`,
  }

  // {
  //   url: null,
  //   name: "Reports",
  //   show:JSON.parse(localStorage.getItem("userInfo")).userRole=='admin',
  //   // tag: "2",
  //   // tagColor: "primary",
  //   icon: "TrendingUpIcon",
  //   i18n: "Reports",
  //   submenu: [
  //     {
  //       url: '/CourseCatalogReport',
  //       name: "CourseCatalog",
  //       slug: "dashboard-analytics",
  //       i18n: "Course Catalog",
  //     },
  //   ]
  // },
];
