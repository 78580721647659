<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0 " >
      <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
        v-if="!$route.path.toLowerCase().includes('CompletedRegisteration'.toLowerCase())"
      >

        <template v-if="windowWidth >= 992">
          <!-- STARRED PAGES - FIRST 10 -->
          <ul class="vx-navbar__starred-pages">
            <draggable
              v-model="starredPagesLimited"
              :group="{ name: 'pinList' }"
              class="flex cursor-move"
            >
              <li
                class="starred-page"
                v-for="page in starredPagesLimited"
                :key="page.url"
              >
                <vx-tooltip :text="page.label" position="bottom" delay=".3s">
                  <feather-icon
                    svgClasses="h-6 w-6"
                    class="p-2 cursor-pointer"
                    :icon="page.labelIcon"
                    @click="$router.push(page.url).catch(() => {})"
                  />
                </vx-tooltip>
              </li>
            </draggable>
          </ul>

          <!-- STARRED PAGES MORE -->
          <div
            class="vx-navbar__starred-pages--more-dropdown"
            v-if="starredPagesMore.length"
          >
            <vs-dropdown vs-custom-content vs-trigger-click>
              <feather-icon
                icon="ChevronDownIcon"
                svgClasses="h-4 w-4"
                class="cursor-pointer p-2"
              ></feather-icon>
              <vs-dropdown-menu>
                <ul class="vx-navbar__starred-pages-more--list">
                  <draggable
                    v-model="starredPagesMore"
                    :group="{ name: 'pinList' }"
                    class="cursor-move"
                  >
                    <li
                      class="starred-page--more flex items-center cursor-pointer"
                      v-for="page in starredPagesMore"
                      :key="page.url"
                      @click="$router.push(page.url).catch(() => {})"
                    >
                      <feather-icon
                        svgClasses="h-5 w-5"
                        class="ml-2 mr-1"
                        :icon="page.labelIcon"
                      ></feather-icon>
                      <span class="px-2 pt-2 pb-1">{{ page.label }}</span>
                    </li>
                  </draggable>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>


          <div style="cursor: pointer;" ng-hide="Role !=patient">
              <img  @click="GoToHome" style="cursor: pointer;" src="@/assets/images/header_logo.png" class="logoSize postion " alt=""/>
          </div>

          <div class="bookmark-container" v-if="false">
            <feather-icon
              icon="StarIcon"
              svgClasses="stoke-current text-warning"
              class="cursor-pointer p-2"
              @click.stop="
                showBookmarkPagesDropdown = !showBookmarkPagesDropdown
              "
            />
            <div
              v-click-outside="outside"
              class="absolute bookmark-list w-1/3 xl:w-1/4 mt-4"
              v-if="showBookmarkPagesDropdown"
               >
              <vx-auto-suggest
                :autoFocus="true"
                :data="navbarSearchAndPinList"
                @selected="selected"
                @actionClicked="actionClicked"
                inputClassses="w-full"
                show-action
                show-pinned
                background-overlay
              ></vx-auto-suggest>
            </div>
          </div>
        </template>


         <ul  v-if="$acl.check('patient')||$acl.check('notAuthenticated')" class="menu-items mx-auto flex items-center">
          <li @click="GoToHome()" class="na-link menu-items mx-auto flex items-center" style="cursor: pointer;">
          Home
         </li>
          <li
            v-for="(item, index) in navMenuItems"
            :key="index"
          >
            <!-- If header -->
            <template v-if="item.header">
              <h-nav-menu-header :header="item" class="menu-header relative"  />
            </template>

            <!-- If it's group -->
            <template v-else-if="item.submenu">
              <h-nav-menu-group
                class="menu-group relative py-4"
                bottom
                :key="`group-${index}`"
                :group="item"
                :groupIndex="index"
                :open="checkGrpChildrenActive(item)"
              />
            </template>

            <!-- If it's link -->
            <div v-else-if="item.url" class="menu-link">
              <h-nav-menu-item
                class="relative  cursor-pointer"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                 :image="item.image"
                :target="item.target"
                :isDisabled="item.isDisabled"
                :slug="item.slug"
              >
               <!-- <img style="width:30px" class="ml-1 mr-1" :src="item.image" alt="menu" /> -->
                <span class="truncate MainHead" >
                {{$t(item.name) }}</span>
                <vs-chip :color="item.tagColor" v-if="item.tag">{{
                  item.tag
                }}</vs-chip>
              </h-nav-menu-item>
            </div>
          </li>

          <li>
              <i18n style=""  />
          </li>
        </ul>

         <ul v-else class="menu-items mx-auto flex items-center">
         </ul>
        <div
          class="search-full-container w-full h-full absolute left-0"
          :class="{ flex: showFullSearch }"
          v-show="showFullSearch"
        >
          <vx-auto-suggest
            class="w-full"
            inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus"
            :autoFocus="showFullSearch"
            :data="navbarSearchAndPinList"
            icon="SearchIcon"
            placeholder="Search..."
            ref="navbarSearch"
            @closeSearchbar="showFullSearch = false"
            @selected="selected"
            background-overlay
          />
          <div class="absolute right-0 h-full z-50">
            <feather-icon
              icon="XIcon"
              class="px-4 cursor-pointer h-full close-search-icon"
              @click="showFullSearch = false"
            ></feather-icon>
          </div>
        </div>

        <feather-icon
          icon="SearchIcon"
          v-if="false"
          @click="showFullSearch = true"
          class="cursor-pointer navbar-fuzzy-search mr-4"
        ></feather-icon>

        <!-- USER META -->
        <div
          class="the-navbar__user-meta flex items-center"
          v-if="activeUserInfo.displayName"
        >
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold " >{{ user_displayName }}</p>
          </div>
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer"
          >
            <div class="con-img ml-3">
              <img
                v-if="activeUserImg"
                key="onlineImg"
                :src="baseURL+activeUserImg"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />

              <img
                v-else
                key="onlineImg"
                src="@/assets/images/logo/logo.png"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
               <!-- <vs-avatar
                v-else
              size="70px"
              src="https://i.imgur.com/ezM6SJ5.png"
              class="ml-10"
            /> -->
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem;">
                <li
                v-if="!$acl.check('hospitalgroup')"
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="GoToProfile()"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2  ">{{$t("Profile")}}</span>
                </li>

                <!-- <li
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="$router.push('/apps/chat').catch(() => {})"
                >
                  <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2 ">Messages</span>
                </li> -->
                 <li
                v-if="$store.state.AppActiveUser.Hospital && $store.state.AppActiveUser.Hospital.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="gotoEditProfile"
                >
                  <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">{{$t('HospitalInfo')}}</span>
                </li>
                <li
                v-if="$store.state.AppActiveUser.Patient && $store.state.AppActiveUser.Patient.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/Patient/PatientSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">{{$t("Setting")}}</span>
                </li>

                <li
                      v-if="$store.state.AppActiveUser.Doctor && $store.state.AppActiveUser.Doctor.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/doctor/DoctorSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">{{$t("Setting")}}</span>
                </li>
                  <!-- <li
                      v-if="$store.state.AppActiveUser.Hospital && $store.state.AppActiveUser.Hospital.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/hospital/HospitalSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Setting</span>
                </li> -->
                <vs-divider class="m-1"></vs-divider>

                <li
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">{{$t("Logout")}}</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- NOTIFICATIONS -->
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer ml-5"
          >
            <feather-icon
              icon="BellIcon"
              class="cursor-pointer mt-1 sm:mr-6 mr-2 "
              :badge=" notifications.filter((b)=>b.IsRead==false).length"
            ></feather-icon>
            <vs-dropdown-menu
              class="notification-dropdown dropdown-custom vx-navbar-dropdown"
            >
              <div
                class="notification-top text-center p-5 bg-primary text-white"
              >
                <h3 class="text-white">{{ notifications.filter((b)=>b.IsRead==false).length }} {{$t('New')}}</h3>
                <p class="opacity-75">App Notifications</p>
              </div>

              <VuePerfectScrollbar
                ref="mainSidebarPs"
                class="scroll-area--nofications-dropdown p-0 mb-2"
                :settings="settings"
              >
                <ul class="bordered-items">
                  <li
                    v-for="ntf in notifications"
                    :key="ntf.index" :style="[ntf.IsRead == false? {'backgroundColor':'#e8dfdf'}:'']" @click="openNotification(ntf)"
                    class="flex justify-between px-4 py-4 notification cursor-pointer"
                  >
                    <div class="flex items-start">
                      <feather-icon
                        :icon="ntf.icon"
                        :svgClasses="[
                          `text-${ntf.category}`,
                          'stroke-current mr-1 h-6 w-6'
                        ]"
                      ></feather-icon>
                      <div class="mx-2">
                        <span
                          class="font-medium block notification-title"
                          :class="[`text-${ntf.category}`]"
                          >{{ ntf.Title }}</span
                        >
                        <small>{{ ntf.Message }}</small>
                      </div>
                    </div>
                    <small class="mt-1 whitespace-no-wrap">{{
                      elapsedTime(new Date(ntf.CreatedDate))
                    }}</small>
                  </li>
                </ul>
              </VuePerfectScrollbar>
              <!-- <div
                class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
              >
                <span>View All Notifications</span>
              </div> -->
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <div v-else>
          <vs-button color="#00c425" @click="GoToLogin()"> {{$t("Login")}}</vs-button>
        </div>



      </vs-navbar>

       <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
        v-else
      >

      <div class="vx-navbar__starred-pages w-full" style="height: 62px" >
        </div>
       </vs-navbar>
    </div>

  </div>
</template>

<script>
import VxAutoSuggest from "@/components/vx-auto-suggest/VxAutoSuggest.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import I18n from "./components/I18n.vue";
import themeConfig from "@/../themeConfig.js";
import { domain } from "@/gloabelConstant.js";
import moduleUserNotification from "@/store/userNotification/moduleUserNotification.js";
import HNavMenuGroup from "../horizontal-nav-menu/HorizontalNavMenuGroup.vue";
import HNavMenuHeader from "../horizontal-nav-menu/HorizontalNavMenuHeader.vue";
import HNavMenuItem from "../horizontal-nav-menu/HorizontalNavMenuItem.vue";
export default {
  name: "the-navbar",
  comments:{

  },
  props: {
    DoctorRate:Object,
      navMenuItems: { type: Array, required: true },
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    }
  },
  data() {
    return {

      baseURL:domain,
      IsPatientRole: false,
      IsDoctorRole:false,
      IsHospitalDoctorRole:false,
      IsHospitalRole:false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: "",
      showFullSearch: false,
      unreadNotifications: [
        {
          index: 0,
          title: "New Message",
          msg: "Are your going to meet me tonight?",
          icon: "MessageSquareIcon",
          time: this.randomDate({ sec: 10 }),
          category: "primary"
        },
        {
          index: 1,
          title: "New Order Recieved",
          msg: "You got new order of goods.",
          icon: "PackageIcon",
          time: this.randomDate({ sec: 40 }),
          category: "success"
        },
        {
          index: 2,
          title: "Server Limit Reached!",
          msg: "Server have 99% CPU usage.",
          icon: "AlertOctagonIcon",
          time: this.randomDate({ min: 1 }),
          category: "danger"
        },
        {
          index: 3,
          title: "New Mail From Peter",
          msg: "Cake sesame snaps cupcake",
          icon: "MailIcon",
          time: this.randomDate({ min: 6 }),
          category: "primary"
        },
        {
          index: 4,
          title: "Bruce's Party",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "CalendarIcon",
          time: this.randomDate({ hr: 2 }),
          category: "warning"
        }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false
    };
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown) {
        this.showBookmarkPagesDropdown = false;
      }
    }
  },
  computed: {
     notifications()
    {
      return this.$store.state.notifications
    },
    navbarColor() {
      let color = themeConfig.navbarColor;

      // if (this.navbarType === "sticky") {
      //   color = "#f7f7f7";
      // } else if (this.navbarType === "static") {
      //   if (this.scrollY < 50) {
      //     color = "#f7f7f7";
      //   }
      // }

      // this.isThemedark === "dark"
      //   ? color === "#fff"
      //     ? (color = "#10163a")
      //     : (color = "#262c49")
      //   : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      let style = {};

      if (this.navbarType === "static") {
        style.transition = "all .25s ease-in-out";

        // if(this.scrollY < 50) {
        //   style.background = "#262c49"
        // }
      }

      return style;
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },

    // HELPER
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesLimited", list);
      }
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesMore", list);
      }
    },

    // PROFILE
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    user_displayName() {
      return this.activeUserInfo.displayName;
    },
    activeUserImg() {
      debugger
      if(this.$store.state.AppActiveUser.Doctor&&this.$store.state.AppActiveUser.Doctor.ID>0)
         return this.$store.state.AppActiveUser.Doctor.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Patient&&this.$store.state.AppActiveUser.Patient.ID>0)
         return this.$store.state.AppActiveUser.Patient.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Hospital&&this.$store.state.AppActiveUser.Hospital.ID>0)
         return this.$store.state.AppActiveUser.Hospital.ImagePath;
    }
  },
  methods: {
    gotoEditProfile(){
this.$router.push({

        name: "UpdateHospitalProfile",params:{Id:this.$store.state.AppActiveUser.Hospital.ID}

      });
    },
    checkNotificationAsRead(notificationId)
    {
      this.$store.dispatch("notificationList/SetNotificationAsRead",notificationId).then(()=>{
             this.$store.dispatch("fetchNotifications");
      });

    },
    GoToHome(){
      debugger;
      if ( !this.IsHospitalDoctorRole && !this.IsDoctorRole && !this.IsHospitalRole)
      {
        window.location.replace('https://doclinia.com/');
      }
    },
     openNotification(notification)
    {
      debugger
      this.checkNotificationAsRead(notification.ID);
      this.notifications.splice(0,1);


      if(notification.NotificationTypeID==22)
      {
             this.DoctorRate.DoctorID=notification.DoctorID;
           this.DoctorRate.PatientReserviedSessionID=notification.PatientReserviedSessionID;

             this.$emit('OpenDoctorRate');
      }
      else if(notification.NotificationTypeID==23)
      {
         this.DoctorRate.HospitalID = notification.HospitalID;
         this.DoctorRate.PatientReserviedSurgeryID = notification.PatientReseriedSurgeryID;

          this.$emit('OpenHospitalRate');
      }
      else
        this.$router.push(notification.ReturnInfo);

    },
    GoToProfile()
    {
      if(this.$acl.check("patient"))
        this.$router.push('/patient/home').catch(() => {});
     else if(this.$acl.check("hospital"))
        this.$router.push('/hospital/Home').catch(() => {});
     else
        this.$router.push('/doctor/home').catch(() => {});

    },
    GoToLogin() {
      this.$router.push("/pages/login");
    },
    // GoToHome() {
    //   this.$router.push("/");
    // },
    selected(item) {
      this.$router.push(item.url).catch(() => {});
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
    logout() {


      this.$store.dispatch("auth/logout");

      debugger
      if(this.$acl.check("patient"))
          this.$router.push("/").then(()=>{
                  location.reload();
          }).catch(() => {});
      else
          this.$router.push("/pages/login").then(()=>{
                  location.reload();
          }).catch(() => {});


          this.$acl.change("anonymous")
    },
    outside: function() {
      this.showBookmarkPagesDropdown = false;
    },

    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      let date = new Date();

      if (hr) {
        date.setHours(date.getHours() - hr);
      }
      if (min) {
        date.setMinutes(date.getMinutes() - min);
      }
      if (sec) {
        date.setSeconds(date.getSeconds() - sec);
      }

      return date;
    }
  },
  created()
  {
      if (!moduleUserNotification.isRegistered) {
        debugger;
      this.$store.registerModule("notificationList", moduleUserNotification);
      moduleUserNotification.isRegistered = true;
      this.IsPatientRole = this.$acl.check("patient");
      this.IsHospitalDoctorRole=this.$acl.check("hospitaldoctor");
      this.IsDoctorRole=this.$acl.check("doctor");
      this.IsHospitalRole = this.$acl.check("hospital");
    this.$store.dispatch("fetchNotifications");
    }
  },
  directives: {
    "click-outside": {
      bind: function(el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },

      unbind: function(el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      }
    }
  },
  components: {
    VxAutoSuggest,
    VuePerfectScrollbar,
    draggable,
    I18n,
    HNavMenuGroup ,
    HNavMenuHeader,
    HNavMenuItem ,
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
.vs-button-primary.vs-button-filled
{
  background: green !important;;
}
</style>
<style >
.logoSize{
  width: 100%;
  height: 50px;

}
.postion{
    position: relative;

}
.feather-icon-badge{
  background-color: red !important;
  padding: 10px;
}
 .greenLoginColor {
  color: #00c425 !important;
}
</style>
